<template>
  <BaseField
    ref="field"
    v-bind="$attrs"
    v-on="$listeners"
    @change="_change"
    @iconClick="toggleVisibility"
    :type="_type"
    :icon="icon"
    :errors="errors"
  >
  </BaseField>
</template>

<script>
import eye from '../../img/eye-icon.svg';

import twoWayProperty from 'apps/public/lib/twoWayProperty';
import {
  addError,
  errorFormatter,
  resetValidation,
  validate,
  validates,
  validationData,
  validationProps,
} from 'apps/public/lib/validation';

import BaseField from './base-field.vue';

export default {
  name: 'Password',
  components: { BaseField },
  inheritAttrs: false,
  props: {
    toggled: {},
    ...validationProps,
  },
  data() {
    return {
      ...validationData(this),
    };
  },
  computed: {
    _type() {
      return this._toggled ? 'text' : 'password';
    },
    _toggled: twoWayProperty('toggled'),
    icon() {
      return {
        iconSVG: eye,
        iconTitle: 'Toggle visibility',
        iconFixed: true,
        iconToggled: this._toggled,
      };
    },
  },
  methods: {
    toggleVisibility() {
      this._toggled = !this._toggled;
    },
    async _change(val) {
      this.resetValidation();
    },
    addError,
    errorFormatter,
    resetValidation,
    validate,
    validates,
  },
};
</script>
