var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseField",
    _vm._g(
      _vm._b(
        {
          ref: "field",
          attrs: { type: _vm._type, icon: _vm.icon, errors: _vm.errors },
          on: { change: _vm._change, iconClick: _vm.toggleVisibility },
        },
        "BaseField",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }