import lodashCloneDeep from 'lodash/cloneDeep';
import lodashGet from 'lodash/get';
import lodashSet from 'lodash/set';
import Vue from 'vue';

function fallbackFns(instance, name, fallback) {
  const path = `$twoWayProperty.${name}`;
  let fns = lodashGet(instance, path);

  if (!fns) {
    if (fallback) {
      fns = twoWayProperty(fallback);
    } else {
      const fallbackData = Vue.observable({ [name]: undefined });

      fns = {
        get: function () {
          return fallbackData[name];
        },
        set: function (newValue) {
          fallbackData[name] = newValue;
        },
      };
    }

    lodashSet(instance, path, fns);
  }

  return fns;
}

function defaultEmitParameterFactory(newValue) {
  return [newValue];
}

export default function twoWayProperty(
  name,
  { fallback, emitParameterFactory } = { emitParameterFactory: defaultEmitParameterFactory }
) {
  return {
    get: function () {
      const val = lodashGet(this, name);

      if (val !== undefined) return val;

      return fallbackFns(this, name, fallback).get.apply(this, arguments);
    },
    set: function (newValue) {
      if (lodashGet(this, name) === undefined) {
        fallbackFns(this, name, fallback).set.apply(this, arguments);
      } else {
        const nameParts = name.split('.');

        // If we deal with an object, newValue is a leaf value, rather than root value.
        // We still want to trigger an update for the full object - but we need a value
        // that reflects the full value, doesn't mutate the original value, and that is
        // updated with the new leaf value (newValue)
        if (nameParts.length > 1) {
          newValue = lodashSet(lodashCloneDeep(this[nameParts[0]]), nameParts.slice(1).join('.'), newValue);
        }

        const event = name === 'value' ? 'input' : 'update:' + nameParts[0];

        this.$emit(event, ...emitParameterFactory.call(this, newValue));
      }
    },
  };
}
